import './TestPage.css';

function TestPage() {
  return (
    <header className="App-header">
    <p>Website in progress! Check back soon for cool additions :)</p>
    </header>
  )
}

export default TestPage;